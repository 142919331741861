@import "variables";

html, body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size;
  color: $color-black;
  overflow: hidden;

  height: 100%;  
  margin: 0;
}

img{
  width: 100%;
}

.button{
  border: 2px solid $color-black;
  border-radius: 14px;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  padding: 7px 8px 5px 8px;
  color: $color-black;

  &:hover{
    background: $color-black;
    color: $primary-color !important;
  }
}

a{
  color: $primary-color;
  text-decoration: underline;
}

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-100px);} 
  60% {transform: translateY(-55px);} 
}
@import "variables";
@import "fonts";

.welcome{
    position: relative;  
    background-color: $primary-color;  
    font-family: MonumentExtended;
    text-align: center;
    padding: $gap;
    width: 100%;
    height: 100dvh;
    min-height: -webkit-fill-available;

    &-wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;   
        height: 100%;
    }

    &-content{
        margin:auto;

        font-size: 20px;
        line-height: 20px;
    }
}
@import "variables";

.share-tooltip{
    position: absolute;
    top: 20px;
    right: -60px;
    background-color: $primary-color;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 0.85em;
    padding: 0 4px;
    margin-left: 10px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 1s linear;

    &.active{
        opacity: 0.8;
    }
}
@import "variables";
@import "fonts";

.fixed-bar{
    display: flex;
    position: fixed;
    justify-content: space-between;

    bottom:0%;
    width:100%; 

    padding:$gap $gap 10px $gap;


    .right-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: inherit;
    }

    .left-wrapper{
        display: flex;
        max-width: 160px;
    }

    .btn-share{
      animation: share-shake 5s cubic-bezier(.36,.07,.19,.97);
      animation-iteration-count: infinite;
      transform: translate3d(0, 0, 0);
      z-index: 2;
    }

    .btn-about{
        animation: about-shake 5s cubic-bezier(.36,.07,.19,.97);
        animation-iteration-count: infinite;
        transform: translate3d(0, 0, 0);
        
        margin-left: -50px;
        margin-top: 50px;
    }

    .btn-more{
        width: 104px;
        animation: more-shake 5s cubic-bezier(.36,.07,.19,.97);
        animation-iteration-count: infinite;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes more-shake {
  0% {}
  5% {
    transform: translate3d(8px, -8px, 0);
  }
  8% {
    transform: translate3d(-8px, 8px, 0);
  }
  10% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes share-shake {
  0% {}
  5% {
    transform: translate3d(0, 16px, 0);
  }
  8% {
    transform: translate3d(0, -16px, 0);
  }
  10% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes about-shake {
  0% {}
  5% {
    transform: translate3d(0, -8px, 0);
  }
  8% {
    transform: translate3d(0, 8px, 0);
  }
  10% {
    transform: translate3d(0, 0, 0);
  }
}
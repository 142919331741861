@import "variables";
@import "fonts";

.about-us{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: MonumentExtended;
    text-align: center; 
    top: 0;
    left: 0;   
    width: 100%;
    height: 100dvh;
    min-height: -webkit-fill-available;
    padding: 0 6px;

    &::before{
        content: ' ';
        position: absolute;
        background-color: $color-black;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        opacity: 0.8;
    }

    &.hide{
        display: none;
    }

    &-container{  
        position: relative;      
        background-color: $primary-color;
        height: 500px;
        width: 100%;
        padding: 10px;
        border-radius: 32px;
        z-index: 4;

        animation-name: bounce; 
        animation-duration: 0.5s; 
        animation-fill-mode: both;

        transition: linear 1s;
    }

    &-wrapper{
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;    
        
        border: 2px solid $color-black;
        border-radius: 32px;

        height: 100%; 

        a{
            color: $color-black;            
            text-decoration: underline;
            font-weight: 700;
        }

        .curve{
            position: absolute;
            top: 50%;
            left: 50%;
            padding-left: 45%;
            transform: translate(-50%);
        }
    }

    &-footer{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        width: 100%;

        .light-bulb{
            position: absolute;
            left: 45px;
            width: 57px;
        }

        .tagline{
            position: absolute;                        
            bottom: -8px;
            background: $primary-color;
            font-size: 9px;
            padding: 0 8px;
            text-align: center;
        }
    }

    &-header{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px;

        .btn-close{
            
        }

        .toilet-roll{
            width: 27px;
            margin-left: 45px;
        }
    }

    &-content{        
        margin:auto;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        margin-bottom: 35px;

        &-wrapper{
            margin-top: auto;
        }

        &__title{
            width: 95px;
            margin: auto;
            margin-bottom: $gap/2;

            h2{
                font-size: 20px;

                &::after{
                    content:" ";
                    display: block;
                    border-top: 3px solid $color-black;
                }
            }

            &.submit{
                width: 104px;
            }
        }

        &__desc{
            width: 308px;
            margin-bottom: $gap;
        }

        &__cta{
            .button{
                font-size: 18px;
                text-decoration: none;
            }
        }
    }

    .ddb-logo{
        width: 35px;
        margin: $gap;
    }
}
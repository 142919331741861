@import "variables";
@import "fonts";

.feed-page{
  background-color: $secondary-color;
  color: $color-light-yellow; 
  
  overflow-x: hidden;
  overflow-y: overlay;
  padding-bottom: 150px;

  height: 100dvh;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    width: 8px;    
    border-radius: 4px;
  }

  &__title{
    h1{
      padding:16px 24px;
      font-size: 28px;
      color: $primary-color;
      line-height: 30px;
      font-family: MonumentExtended;
    }
  }

  &__thumbnail{
    img{
      height: auto;
    }
  }

  &__desc{    
    padding: $gap;

    img{
      width: 100%;
      height: 100%;
    }
  }

  &__cta{
    font-size: 14px;
    font-weight: 700;
    padding: 0 $gap;
  }
}
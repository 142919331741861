@import "variables";
@import "fonts";

.loader{
    position: absolute;
    top: 100%;    
    background-color: $primary-color;
    width: 100%;
    height: 100%;
    transition: all .2s ease-out;
    z-index: 9;
    padding: 16px;

    &.active{      
        top: 0;  
    }

    &-wrapper{
        display: none;
        
        flex-direction: column;
        align-items: center;
        justify-content: center;        
        
        height: 100%;

        margin: auto;
        
        &.active{
            display: flex;
        }
    }

    &__img{
        // margin-top: -150px;
    }

    &__title{
        width: 210px;
        text-align: center;

        h2{
            font-size: 28px;
            font-family: MonumentExtended;
            
        }
    }
}